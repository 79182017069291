import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/code-factory.png");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/copilot-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/copilot.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/docs-and-help.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/gitlab.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/jfrog.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/jira.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/kuma.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/mtr.png");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/plantuml.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/shields-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/shields.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/sonarqube-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/sonarqube.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/wiki-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/wiki.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/yam.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/helpers/applications/yopass.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@4.0.2_next@15.3.0_babel-plugin-react-compiler@19.0.0-beta-e993439-20250405_re_aafb2b2482cf1ca3f416549243cae4e7/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.3.0_babel-plugin-react-compiler@19.0.0-beta-e993439-20250405_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/image-component.js");
